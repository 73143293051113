<script setup lang="ts">
import TheIcon from '@/Components/TheIcon.vue'
import { autocompleteSuggestionsRef } from '@/Composables/AutocompleteSuggestionsLogic'
import { Autosuggestion, AutosuggestionItem } from '@/Pages/the-home/interfaces/Search'
import { watch, nextTick } from 'vue'

const props = defineProps<{
    suggestions: Autosuggestion[]
    doAnimation?: boolean,
    activeIndex?: number,
    autocompleteSuggestionsWrap?: HTMLElement | null
}>()

const emit = defineEmits<{
    selectPlace: [value: AutosuggestionItem]
}>()


// A function to check if an element is active
const isSelected = (groupIndex: number, itemIndex: number): boolean => {
    let flatIndex = 0

    for (let i = 0; i < groupIndex; i++) {
        flatIndex += props.suggestions[i].items.length // Calculation of the number of elements in groups of autosuggestion results
    }

    flatIndex += itemIndex // Add the current index in the group

    return flatIndex === props.activeIndex // Check the match with the active index
}

const scrollToActiveSuggestion = (): void => {

    const container = props.autocompleteSuggestionsWrap || autocompleteSuggestionsRef.value
    
    const activeElement = autocompleteSuggestionsRef.value?.querySelector('.autocomplete-selected-press')

    if (container && activeElement) {
        const containerRect = container.getBoundingClientRect()
        const activeRect = activeElement.getBoundingClientRect()

        // If the first autosuggestion result is active, scroll to the beginning of autosuggestion results container
        if (props.activeIndex === 0) {
            container.scrollTop = 0
            return
        }

        // If the active autosuggestion result extends beyond the top or bottom edge
        if (activeRect.top < containerRect.top) {
            container.scrollTop -= containerRect.top - activeRect.top + 15 // Scroll up

        } else if (activeRect.bottom > containerRect.bottom) {
            container.scrollTop += activeRect.bottom - containerRect.bottom + 15 // Scroll down
        }
    }
}

watch(() => props.activeIndex, async () => {
    await nextTick()
    scrollToActiveSuggestion()
})

</script>

<template>
    <div ref="autocompleteSuggestionsRef" class="autocomplete-suggestions" :class="{'hide-dropdown': !doAnimation}">
        <div v-for="(group, groupIndex) in suggestions" :key="groupIndex">
            <div class="autocomplete-group">
                {{ group.category.toUpperCase() }}
            </div>

            <div
                v-for="(item, itemIndex) in group.items"
                :key="`place_${ itemIndex }`"
                class="autocomplete-suggestion autocomplete-selected cursor-pointer"
                :class="{ 'autocomplete-selected-press': isSelected(groupIndex, itemIndex) }"
                @click="emit('selectPlace', item)"
            >
                <img
                    v-if="item.img"
                    :src="item.img"
                    class="autocomplete-img"
                    :alt="item.query"
                >

                <TheIcon
                    v-else
                    icon-id="map-place"
                    width="24"
                    height="25"
                />

                <span v-html="item.highlighted" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
#heroSearch .hide-dropdown {
    @media (min-width: 992px) {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        padding: 0 32px;
    }  
}

.autocomplete-selected-press {
    background: var(--accent-bg);
    color: rgba(67, 105, 100, 0.75);
}
</style>